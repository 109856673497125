.Registration {
  display: flex;
  width: 100vmax;
/*   margin: 0 auto;
 */  flex-direction: column;
  text-align: center;
  padding: 5%;
  background-image: url('../../media/3695.jpg');
  background-size: cover;
  
}
.footer_link:hover{
  color: rgb(16, 85, 16);
  text-decoration-line: underline;
}
@media screen and (max-width:800px){
  .Registration{
    background-size: auto;
  }
}