@import url('https://fonts.googleapis.com/css?family=Josefin+Sans&display=swap');
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Josefin Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --font-size: calc(10px + 1vmin);
}
