@import url(https://fonts.googleapis.com/css?family=Satisfy&display=swap);
@import url(https://fonts.googleapis.com/css?family=Josefin+Sans|Playfair+Display&display=swap);
@import url(https://fonts.googleapis.com/css?family=Josefin+Sans&display=swap);
.main_header {
  display: inherit;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  /* background-color: rgba(250, 235, 215, 0.767); */
  background-color: rgb(245, 248, 248);
  -webkit-filter: drop-shadow(0 0 10px rgb(172, 172, 172));
          filter: drop-shadow(0 0 10px rgb(172, 172, 172));
}

h1{
  padding-left: 3%;
}

a.app_title {
  font-family: 'Satisfy', cursive;
}

a {
  text-decoration: none;
  color: black; 
}

.links a:hover  {
  color: rgb(16, 85, 16);
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.links {
  display: inherit;
  padding: 3%;
  line-height: 24px;
  text-align: right;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  width: 20%;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

@media screen and (max-width: 500px){
  .main_header {
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
  }
  .links {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}
.LoginForm {
  display: inherit;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-content: space-around;
          align-content: space-around;
  
}


input {
margin: 1%;
border: solid 1px rgb(233, 233, 241);
border-radius: 5px;
padding: 1%;
background-color: rgb(244, 244, 245)
}

input:hover {
  border: rgb(243, 7, 7) solid 1px;
}

.RegistrationFooter a{
padding-top: 2%;
}
.Button {
  border-radius: 5px;
  border-style: none;
  padding: 1% 4%;
  margin: 3%;
  background-color: rgb(1, 58, 1);
  color: whitesmoke;
}

.Button:hover {
  background-color: green;
  font-weight: bold;
}

.Registration {
  display: -webkit-flex;
  display: flex;
  width: 100vmax;
/*   margin: 0 auto;
 */  -webkit-flex-direction: column;  flex-direction: column;
  text-align: center;
  padding: 5%;
  background-image: url(/static/media/3695.3d5255f8.jpg);
  background-size: cover;
  
}
.footer_link:hover{
  color: rgb(16, 85, 16);
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
@media screen and (max-width:800px){
  .Registration{
    background-size: auto;
  }
}
.Login {
  display: -webkit-flex;
  display: flex;
  width: 100vmax;
/*   margin: 0 auto;
 */  -webkit-flex-direction: column;  flex-direction: column;
  text-align: center;
  padding: 5%;
  background-image: url(/static/media/3695.3d5255f8.jpg);
  background-size: cover;
  
}
.dashboard {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  background-image: url(/static/media/illustration-mediterranean-city_53876-147.22319cca.jpg);
  background-repeat: no-repeat;  
  background-size: cover;
} 

.dash-title{
  /* border: purple solid 2px; */
  font-weight: 200;
  font-size: 1.2em;
}

.dash-header{
  /* border: yellow solid 2px; */
  padding: 10px 40px;
  text-align: right;
  width: 100%;
  display: -webkit-flex;
  display: flex; 
  -webkit-flex-direction: row; 
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: baseline;
          align-items: baseline;
}

.language-score{
  /* border: pink solid 2px; */
  width: 300px;
  display: -webkit-flex;
  display: flex; 
  -webkit-flex-direction: row; 
          flex-direction: row;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.dashboard-word-list {
  /* border: green solid 2px; */
  list-style: none;
  padding: 0 ;
  width: 60%;
  margin: auto;
  display: grid;
  grid-template-columns: 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr;
  grid-template-rows: 150px 150px; 
  -webkit-justify-content: center; 
          justify-content: center;
}


.dashboard-word{
  border: rgb(244, 226, 205) solid 1px;
  background-color: rgb(255, 255, 255);
  margin: 15px;
  -webkit-filter: drop-shadow(0 0 5px rgb(193, 183, 183));
          filter: drop-shadow(0 0 5px rgb(193, 183, 183));
}

.dash-footer{
  /* border: blue solid 2px; */
  height: 160px;
  width: 100vmax;
  bottom: 0;
}

button{
  font-size: 1em;
  -webkit-filter: drop-shadow(0 0 5px rgb(193, 183, 183));
          filter: drop-shadow(0 0 5px rgb(193, 183, 183));
}

@media screen and (max-width: 850px) {
  .dashboard-word-list{
    grid-template-columns: 0.2fr 0.2fr;
    grid-template-rows: 120px 1fr; 
  }
  
  .dashboard-word{
    border: rgb(244, 226, 205) solid 1px;
    background-color: rgb(255, 255, 255);
    margin: 15px;
    -webkit-filter: drop-shadow(0 0 5px rgb(193, 183, 183));
            filter: drop-shadow(0 0 5px rgb(193, 183, 183));
  }
}
.learning-route{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: center;
  width: 100%;
  bottom: 0;
  background-image:url(/static/media/capstone2-italy-banner2.61292e1f.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  margin-top: 230px;
}

.learning-buttons{
  margin: -20px;
}

.learning-buttons button{
  width: 270px;
}

.word{
  /* border: blue solid 2px; */
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: baseline;
          align-items: baseline;
  text-align: center;
  width: 100%;
  margin-top: -220px;
}

.word span{
  padding-left: 10px; 
  font-size: 1.5em;
}

.learn-guess-input-form input{
  width: 300px;
}

hr{
  width: 200px;
}

.correct-answer{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0px;
  text-align: center;
  width: 100%;
  margin: -150px auto 0 auto;
  background-image: url(/static/media/illustration-mediterranean-city_53876-147.22319cca.jpg);
  background-repeat: no-repeat;  
  background-size: cover;
}

.correct-feedback{
  border: rgb(244, 226, 205) solid 1px;
  background-color: rgb(255, 255, 255);
  -webkit-filter: drop-shadow(0 0 5px rgb(193, 183, 183));
          filter: drop-shadow(0 0 5px rgb(193, 183, 183));
  width: 40%;
  margin: 0 auto;
}

.correct-answer button{
  margin: 50px auto;
  width: 200px;
  -webkit-filter: drop-shadow(0 0 5px rgb(193, 183, 183));
          filter: drop-shadow(0 0 5px rgb(193, 183, 183));
}
.incorrect-answer{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0px;
  text-align: center;
  width: 100%;
  margin: -150px auto 0 auto;
  background-image: url(/static/media/illustration-mediterranean-city_53876-147.22319cca.jpg);
  background-repeat: no-repeat;  
  background-size: cover;
}

.incorrect-feedback{
  border: rgb(244, 226, 205) solid 1px;
  background-color: rgb(255, 255, 255);
  -webkit-filter: drop-shadow(0 0 5px rgb(193, 183, 183));
          filter: drop-shadow(0 0 5px rgb(193, 183, 183));
  width: 40%;
  margin: 0 auto;
}

.incorrect-answer button{
  margin: 50px auto;
  width: 200px;
  -webkit-filter: drop-shadow(0 0 5px rgb(193, 183, 183));
          filter: drop-shadow(0 0 5px rgb(193, 183, 183));
}
.App {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;  
  max-height: 100vh;
}

main {
  display: -webkit-flex;
  display: flex; 
  height: 100vh;
 
}

.Subheader {
  font-family: 'Josefin Sans', sans-serif;
}

@media screen and (max-width: 975px){
  main{
  background-size: auto;
  }
}
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Josefin Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --font-size: calc(10px + 1vmin);
}

