.Button {
  border-radius: 5px;
  border-style: none;
  padding: 1% 4%;
  margin: 3%;
  background-color: rgb(1, 58, 1);
  color: whitesmoke;
}

.Button:hover {
  background-color: green;
  font-weight: bold;
}