.dashboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  background-image: url('../../media/illustration-mediterranean-city_53876-147.jpg');
  background-repeat: no-repeat;  
  background-size: cover;
} 

.dash-title{
  /* border: purple solid 2px; */
  font-weight: 200;
  font-size: 1.2em;
}

.dash-header{
  /* border: yellow solid 2px; */
  padding: 10px 40px;
  text-align: right;
  width: 100%;
  display: flex; 
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.language-score{
  /* border: pink solid 2px; */
  width: 300px;
  display: flex; 
  flex-direction: row;
  justify-content: space-evenly;
}

.dashboard-word-list {
  /* border: green solid 2px; */
  list-style: none;
  padding: 0 ;
  width: 60%;
  margin: auto;
  display: grid;
  grid-template-columns: 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr;
  grid-template-rows: 150px 150px; 
  justify-content: center;
}


.dashboard-word{
  border: rgb(244, 226, 205) solid 1px;
  background-color: rgb(255, 255, 255);
  margin: 15px;
  filter: drop-shadow(0 0 5px rgb(193, 183, 183));
}

.dash-footer{
  /* border: blue solid 2px; */
  height: 160px;
  width: 100vmax;
  bottom: 0;
}

button{
  font-size: 1em;
  filter: drop-shadow(0 0 5px rgb(193, 183, 183));
}

@media screen and (max-width: 850px) {
  .dashboard-word-list{
    grid-template-columns: 0.2fr 0.2fr;
    grid-template-rows: 120px 1fr; 
  }
  
  .dashboard-word{
    border: rgb(244, 226, 205) solid 1px;
    background-color: rgb(255, 255, 255);
    margin: 15px;
    filter: drop-shadow(0 0 5px rgb(193, 183, 183));
  }
}