@import url('https://fonts.googleapis.com/css?family=Josefin+Sans|Playfair+Display&display=swap');

.App {
  display: flex;
  flex-direction: column;  
  max-height: 100vh;
}

main {
  display: flex; 
  height: 100vh;
 
}

.Subheader {
  font-family: 'Josefin Sans', sans-serif;
}

@media screen and (max-width: 975px){
  main{
  background-size: auto;
  }
}