.correct-answer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px;
  text-align: center;
  width: 100%;
  margin: -150px auto 0 auto;
  background-image: url('../../media/illustration-mediterranean-city_53876-147.jpg');
  background-repeat: no-repeat;  
  background-size: cover;
}

.correct-feedback{
  border: rgb(244, 226, 205) solid 1px;
  background-color: rgb(255, 255, 255);
  filter: drop-shadow(0 0 5px rgb(193, 183, 183));
  width: 40%;
  margin: 0 auto;
}

.correct-answer button{
  margin: 50px auto;
  width: 200px;
  filter: drop-shadow(0 0 5px rgb(193, 183, 183));
}